import {CircularProgress, Typography} from "@material-ui/core";
import React, {useEffect} from "react";
import styled from "styled-components";
import CouponListItem from "../components/App/Coupons/CouponListItem";
import CustomizedDialog from "../components/CustomizedDialog";
import {useCoupon} from "../context/CouponContext";
import {Carousel} from "antd";
import "antd/dist/antd.css";
import "../styles/AntdCarousel.css";
import PageTopText from "../components/PageTopText";
import {useTranslation} from "react-i18next/";
import {Helmet} from "react-helmet";

const CouponsPage = () => {
    const {couponsData, couponsLoading, couponsError, companiesError} = useCoupon();
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const CouponViewer = (from: number, to?: number) => {
        const couponsCount = couponsData?.length;

        return (
            <ParentDiv>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    {couponsData?.slice(from, to ?? couponsCount).map((coupon) => {
                        return <CouponListItem key={coupon.id} coupon={coupon}/>;
                    })}
                </div>
            </ParentDiv>
        );
    };

    const AdViewer = (h1: string, s1: string, h2: string, s2: string) => {
        return (
            <CarouselParent>
                <Carousel autoplay swipe swipeToSlide draggable>
                    <a target="_blank" href={h1}>
                        <Img src={s1}/>
                    </a>
                    <a target="_blank" href={h2}>
                        <Img src={s2}/>
                    </a>
                </Carousel>
            </CarouselParent>
        );
    };

    const pageInfo = <PageTopText t1="Coupons" t2={t("CouponsPageTopTextInfo")}/>;

    if (couponsLoading) {
        return (
            <>
                {pageInfo}
                <div style={{display: "flex", justifyContent: "center", marginBottom: "70vh"}}>
                    <CircularProgress size={60}/>
                </div>
            </>
        );
    }

    if (couponsError || companiesError) {
        return (
            <>
                {pageInfo}
                <div style={{display: "flex", justifyContent: "center", marginBottom: "70vh"}}>
                    <Typography variant="h6">Something went wrong...</Typography>
                </div>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{t("HelmetCouponsTitle")}</title>
                <meta name="description" content={t("HelmetCouponsContent")}/>
            </Helmet>

            <CustomizedDialog/>
            {pageInfo}

            {CouponViewer(0, 6)}
            {AdViewer(
                "https://www.holdurcarrental.is/",
                "https://res.cloudinary.com/dineout/image/upload/v1744104375/couponsweb/vefbordar/holdur-25_zviktc.png",
                "http://www.kaldibar.com/",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/kaldi_auglkaldibar.is.jpg",
            )}
            {CouponViewer(6, 12)}
            {AdViewer(
                "https://www.flyovericeland.com/",
                "https://res.cloudinary.com/dineout/image/upload/v1700568681/couponsweb/vefbordar/ik02wkbock8njeu5yfpq.jpg",
                "https://monkeys.is/en/monkeys-english/",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/Monkeys-Coupons-1440x500.png",
            )}
            {CouponViewer(12, 18)}
            {AdViewer(
                "https://www.collabiceland.is/english",
                "https://res.cloudinary.com/dineout/image/upload/v1728317726/couponsweb/vefbordar/hzaqnewc48gdkf4mz8w2.jpg",
                "https://kolrestaurant.is/",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/KOL-Coupons-1440x500.png",
            )}
            {CouponViewer(18, 24)}
            {AdViewer(
                "https://reykjaviksailors.is",
                "https://res.cloudinary.com/dineout/image/upload/v1728317726/couponsweb/vefbordar/e03fsdj2wry96qzmq35d.jpg",
                "https://www.dineout.is",
                "https://res.cloudinary.com/dineout/image/upload/v1728318753/couponsweb/vefbordar/zwoa0powjsqnvctppwfu.png",
            )}
            {CouponViewer(24, 33)}
            {AdViewer(
                "http://www.fiskfelagid.is",
                "https://res.cloudinary.com/dineout/image/upload/v1700568301/couponsweb/vefbordar/jmwwyigmxtxmvcbsaqiz.png",
                "https://bastard.is/",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/BastardOKT1440x500.jpg",
            )}
            {CouponViewer(33, 42)}
            {AdViewer(
                "https://www.flyovericeland.com/",
                "https://res.cloudinary.com/dineout/image/upload/v1700568681/couponsweb/vefbordar/ijn9gccrohqdc1ucgjwy.jpg",
                "https://www.imagineicelandtravel.com",
                "https://res.cloudinary.com/dineout/image/upload/v1728317727/couponsweb/vefbordar/oad3zakhffa5zaxv4khx.png",
            )}
            {CouponViewer(42, 51)}
            {AdViewer(
                "https://www.jomfruin.is/?lang=en",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/jomfruin1440x500OKT.jpg",
                "https://sushisocial.is/",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/sushisocial_auglsushisocial.is.jpg",
            )}
            {CouponViewer(51, 60)}
            {AdViewer(
                "https://kolrestaurant.is/",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/KOL-Coupons-1440x500.png",
                "https://www.icewear.is/is/?___store=is",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/Heimasi%CC%81%C3%B0a+1440x500+(1).jpg",
            )}
            {CouponViewer(60, 69)}
            {AdViewer(
                "https://saetasvinid.is",
                "https://res.cloudinary.com/dineout/image/upload/v1728317726/couponsweb/vefbordar/devphumpk1p61tpqiwkn.png",
                "http://www.kaldibar.com/",
                "https://s3.eu-west-2.amazonaws.com/couponswebapp/ads/kaldi_auglkaldibar.is.jpg",
            )}
            {CouponViewer(69, 78)}
            {AdViewer(
                "https://intotheglacier.is/",
                "https://res.cloudinary.com/dineout/image/upload/v1728317726/couponsweb/vefbordar/cyigwqrqva3ptw4lw6v0.png",
                "https://adventures.is/",
                "https://res.cloudinary.com/dineout/image/upload/v1728317726/couponsweb/vefbordar/s766mntnvl0ofvailcub.png",
            )}
            {CouponViewer(78, 87)}
            {AdViewer(
                "http://www.fiskfelagid.is",
                "https://res.cloudinary.com/dineout/image/upload/v1700568301/couponsweb/vefbordar/jmwwyigmxtxmvcbsaqiz.png",
                "https://www.flyovericeland.com/",
                "https://res.cloudinary.com/dineout/image/upload/v1700568681/couponsweb/vefbordar/lrkczecionyipxtgbccc.jpg",
            )}
            {CouponViewer(87)}
        </>
    );
};

const CarouselParent = styled.div`
    max-width: 1098px;
    cursor: pointer;
    margin: auto;
    padding: 0px;
`;

const Img = styled.img`
    width: 100%;
`;

const ParentDiv = styled.div`
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 12px;
    padding-top: 4px;
    flex-direction: column;
    align-items: center;
`;

export default CouponsPage;
